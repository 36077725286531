<template>
    <div class="view pa24">
        <div class="d-flex">
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" @click="addData" :loading="addLoading">新增互通设置</el-button>
                <el-button class="ma mr10" type="primary" @click="showAddModel">填写邀请码</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="fromName" align="center" label="我的小程序" show-overflow-tooltip />
                <el-table-column prop="toName" align="center" label="互通小程序" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.toName || "无" }}</template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="邀请码" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.linkKey }} <el-button type="text"
                            @click="copyFun(scope.row.linkKey)">复制</el-button></template>
                </el-table-column>
                <el-table-column prop="name" align="center" width="120" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag>{{ getState(scope.row) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">
                    <template slot-scope="scope">
                        <customPopconfirm confirm-button-text="确定" v-if="scope.row.toName" cancel-button-text="取消"
                            @confirm="delFun(scope.row)" icon="el-icon-info" title="确定要取消这条钱包互通记录？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">取消互通</el-button>
                        </customPopconfirm>
                        <customPopconfirm confirm-button-text="确定" v-else cancel-button-text="取消"
                            @confirm="delFun(scope.row)" icon="el-icon-info" title="确定要删除这条钱包互通记录？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="钱包互通" :visible.sync="showModel"
            :destroy-on-close="true" width="300px" center>
            <el-input v-model="formData.linkKey" placeholder="输入邀请码进行互通" clearable></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm" :loading="loadingBtn">互 通</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { addAccountWalletLink, delAccountWalletLink, getAccountWalletLinkList, updateAccountWalletLink } from "@/api/association";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec, copyText } from "@/utils";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            addLoading: false,
            formData: {
                linkKey: "",
            },
            loadingBtn: false,
        };
    },
    computed: {
        getState() {
            return (row) => {
                if (row.fromDel || row.toDel) {
                    return '待取消互通'
                }
                if (!row.toName) {
                    return '待互通'
                } else {
                    return '正 常'
                }
            }
        },
    },
    created() {
        this.getList();
    },
    methods: {
        copyFun(text) {
            copyText(text)
            this.$message.success('复制成功！');
        },
        /**@method 新增互通记录 */
        addData() {
            this.addLoading = true;
            addAccountWalletLink({ accountType: 9 }).then(res => {
                this.addLoading = false;
                if (res.code === 200) {
                    this.currentPage = 1;
                    this.getList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.message);
                this.addLoading = false;
            })
        },
        /**@method 提交数据 */
        submitForm() {
            this.loadingBtn = true;
            updateAccountWalletLink({
                accountType: 9,
                linkKey: this.formData.linkKey,
            }).then(res => {
                this.loadingBtn = false;
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.message);
                this.loadingBtn = false;
            })
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                accountType: 9
            };
            try {
                this.loading = true;
                let result = await getAccountWalletLinkList(params)
                this.loading = false;
                const { data } = result;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
        },
        /**@method 删除 */
        delFun(row) {
            let params = {
                accountType: 9,
                linkId: row.linkId
            };
            if (!row.toName) {
                params.isDel = 1;
            }
            delAccountWalletLink(params).then(res => {
                if (res.code === 200) {
                    this.currentPage = 1;
                    this.getList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.message);
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>